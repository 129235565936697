import axios from "axios";
import * as AxiosLogger from "axios-logger";
import { setGlobalConfig } from "axios-logger";

setGlobalConfig({
    prefixText: "http-client",
    dateFormat: "HH:MM:ss",
    status: true,
    headers: false,
});

const instance = axios.create();

instance.interceptors.request.use(
    AxiosLogger.requestLogger,
    AxiosLogger.errorLogger
);

// instance.interceptors.request.use((config) => {
//     const jwt = getToken();
//     if (config.headers && jwt) {
//         config.headers.Authorization = `Bearer ${jwt}`;
//     }
//     return config;
// })

instance.interceptors.response.use(AxiosLogger.requestLogger, (err) => {
    // if (err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
    //     logout();
    // }
    console.log(err);
})

export default instance;