import React from 'react';
import Footer from '../components/Footer';
// import NavV2 from '../components/NavV2';
import './TnC.css';  // Importing the CSS file for styles

function TnC() {
  return (
    <>
        {/* <NavV2/> */}
        <div className="tncContainer">
        <h1>Terms of Service for Qrio Space</h1>
        <p>Welcome to Qrio Space! We are an online educational platform dedicated to providing interactive and experiential learning opportunities for K-12 students, focusing on Mathematics and Science through simulations.</p>
        <p>This website is operated by RAPT LEARN PRIVATE LIMITED. Throughout the site, the terms “we”, “us” and “our” refer to RAPT LEARN PRIVATE LIMITED. RAPT LEARN PRIVATE LIMITED offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
        <p>By using our services, you agree to comply with and be bound by the following terms and conditions.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing and using the Qrio Space platform, you confirm that you have read, understood, and agree to be bound by these terms of service. If you do not agree with any part of these terms, you should not use our platform.</p>
        
        <h2>2. User Eligibility</h2>
        <p>The Qrio Space services are intended for users who are at least the age of majority in their place of residence. Users under the age of majority must have permission from a parent or legal guardian who agrees to be bound by these Terms of Service.</p>
        
        <h2>3. Account Registration</h2>
        <p>To access certain features of our platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
        
        <h2>4. Intellectual Property Rights</h2>
        <p>All materials provided on the Qrio Space platform, including but not limited to text, graphics, logos, images, and software, are the property of Qrio Space and are protected by copyright laws. You are granted a limited, non-exclusive right to access and use these materials for personal, educational purposes only.</p>
        
        <h2>5. Prohibited Uses</h2>
        <p>You may not use our platform for any unlawful purpose or engage in any other use prohibited by these terms, including but not limited to: copying, modifying, or distributing our content without permission; using the platform in a way that could damage, disable, overburden, or impair it; or using automated systems or software to extract data from our website for commercial purposes ('screen scraping').</p>
        
        <h2>6. Modifications to the Service</h2>
        <p>Qrio Space reserves the right to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. We will not be liable to you or to any third party for any modification, suspension, or discontinuance of the service.</p>
        
        <h2>7. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
        
        <h2>8. Governing Law</h2>
        <p>These Terms of Service shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
        
        <h2>9. Changes to Terms</h2>
        <p>We reserve the right, at our sole discretion, to modify or replace these terms at any time. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms.</p>
        
        <h2>10. Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us at <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a>.</p>
        </div>
        <div className='policy-footer-div'>
            <Footer/>
        </div>
    </>
  )
}

export default TnC;
