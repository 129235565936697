import React from 'react';
import Footer from '../components/Footer';
// import NavV2 from '../components/NavV2';
import './PrivacyPolicy.css';  // Importing the CSS file for styles

function PrivacyPolicy() {
  return (
    <>
        {/* <NavV2/> */}
        <div className="privacyContainer">
        <h1>Privacy Policy for Qrio Space</h1>
        <p>Welcome to Qrio Space! Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, protect, and handle your personal information when you use our website and services. By using Qrio Space, you consent to the data practices described in this policy.</p>

        <h2>1. Information Collection and Use</h2>
        <p>When you register on our platform, we collect personal information such as your name, email address, phone number and school details. This information is used to create your account, provide our services, and communicate important notifications.</p>
        
        <h2>2. Consent</h2>
        <p>How do we obtain your consent? Consent is obtained when you provide us with personal information to complete a registration, transaction. By providing such information, you agree to our collecting and using it for that specific purpose.</p>
        <p>How can you withdraw your consent? If you wish to withdraw your consent at any time, you may contact us at <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a>, and we will promptly facilitate the withdrawal of consent and deletion of your information from our records, except as required by law.</p>
        
        <h2>3. Disclosure</h2>
        <p>We may disclose your personal information if required by law or if you violate our Terms of Service. Additionally, for operational purposes, we may share your information with trusted third-party service providers who assist us in conducting our business, such as payment processors or hosting services. These third parties agree to adhere to our privacy standards.</p>
        
        <h2>4. Payment</h2>
        <p>We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.</p>
        <p>Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.</p>
        <p>PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.</p>
        <p>For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com </p>
        
        <h2>5. Third-Party Services</h2>
        <p>In general, the third-party providers used by us will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us. Remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us.</p>
        
        <h2>6. Security</h2>
        <p>To protect your personal information, we take reasonable precautions and follow industry best practices to ensure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed. This includes using encryption methods and secure servers.</p>
        
        <h2>7. Cookies</h2>
        <p>We use cookies to maintain the session of your user. They help us improve your access to our site and identify repeat visitors. Cookies can also enable us to track and target the interests of our users to enhance the experience on our platform.</p>
        
        <h2>8. Age of Consent</h2>
        <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and have given us your consent to allow any of your minor dependents to use this site.</p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
        
        <h2>Contact Information</h2>
        <p>If you have any questions about this Privacy Policy, or if you would like to access, correct, amend, or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a>.</p>
        </div>
        <div className='policy-footer-div'>
            <Footer/>
        </div>
    </>
  )
}

export default PrivacyPolicy;
