import httpClient from "../services/http.service";
import { GET_EVENTS } from "./api-constants";

export const getEventsApi = async () => {
    try {
        const response = await httpClient.get(`${GET_EVENTS}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}