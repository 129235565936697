import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';


function Footer() {

    const navigate = useNavigate();
    const handleNameClick=()=>{
        navigate('/');
    }

    const whatsAppNumber = '+919654073981'; 
    const preFilledMessage = 'Hello, I want to know more about GlutaClass';

    const handleContactUsClick = () => {
        const encodedMessage = encodeURIComponent(preFilledMessage); 
        const whatsappUrl = `https://wa.me/${whatsAppNumber}?text=${encodedMessage}`;
        window.location.href = whatsappUrl;
    };

    const handleClassSelect = (standard) => {
        navigate(`/online-class/${standard}`);
    }
    const handlePricingClick=()=>{
        navigate('/pricing');

    }
    const handlePrivacyPolicyClick=()=>{
        navigate('/privacy-policy');

    }
    const handleRefundPolicyClick=()=>{
        navigate('/refund-policy');

    }
    const handleTnCClick=()=>{
        navigate('/terms');
    }
    const handleAsyncLearning=()=>{
      navigate('/resources');

    }
    return (
        <div className='footer-cont'>
            <div className='footer-top'>
                <div className='footer-glutaclass'>
                    <div className='footer-heading' onClick={handleNameClick}>Qrio Space</div>
                    <div className='footer-desc'>QrioKids are smart passionate individuals
                    who can communicate their opinions clearly</div>
                    <p style={{fontSize:'1.25rem', fontWeight:'700', paddingTop:'1rem', paddingBottom:'0.25rem'}}>Reach out to us</p>
                    <p>Call +919560109183</p>
                    <p>Email glutaclass@gmail.com</p>
                    <iframe
                        src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fglutaclass.com&width=32px&layout&action&size&share=true&height=35&appId"
                        width='fit-content'
                        height="35"
                        style={{ border: 'none', overflow: 'hidden', marginTop:'1rem' }}
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        ></iframe>                
                    </div>
                <div className='footer-right'>
                    <div className='footer-contact-us'>
                        {/* <p onClick={handleContactUsClick}>Contact Us</p> */}
                        {/* <p>Careers</p> */}
                        {/* <p onClick={handlePricingClick}>Pricing</p> */}
                        <p onClick={handlePrivacyPolicyClick}>Privacy Policy</p>
                        <p onClick={handleRefundPolicyClick}>Refund Policy</p>
                        <p onClick={handleTnCClick}>Terms and Conditions</p>
                        <p onClick={handleNameClick}>Qrio Space</p>
                    </div>
                    {/* <div className='footer-classes'>
                        <p onClick={() => handleClassSelect(6)}>Class 6</p>
                        <p onClick={() => handleClassSelect(7)}>Class 7</p>
                        <p onClick={() => handleClassSelect(8)}>Class 8</p>
                        <p onClick={() => handleAsyncLearning()}>Async Learning</p>
                    </div> */}
                </div>
            </div>
            <div className='footer-seperator'/>
            <div className='footer-bottom footer-desc'>
                <span><span style={{fontWeight:700}}>Delhi NCR:</span> 301, Bellagio Apartment Sector 51, Gurugram</span>
                <span><span style={{fontWeight:700}}>Bangalore:</span> I - 704, Rohan Jharoka 1 Yemalur, Bellandur</span>
            </div>
        </div>
    )
}

export default Footer;
