import React from 'react';
import Footer from '../components/Footer';
// import NavV2 from '../components/NavV2';
import './RefundPolicy.css';  // Importing the CSS file for styles

function RefundPolicy() {
  return (
    <>
        {/* <NavV2/> */}
        <div className="refundContainer">
        <h1>Refund Policy for Qrio Space</h1>
        <p>At Qrio Space, we strive to ensure that our educational services meet your expectations. If, for any reason, you are not completely satisfied with our services, we offer a 7-day cancellation policy, allowing you to cancel your subscription and receive a full refund.</p>
        
        <h2>1. Eligibility for Refunds</h2>
        <p>You are eligible for a refund if you cancel your subscription within 7 days of the purchase date. To qualify for a refund, the following conditions must be met:
        <ul>
            <li>The cancellation request must be submitted within the 7-day window.</li>
            <li>You must not have excessively used the service, as determined by Qrio Space in its sole discretion.</li>
        </ul>
        </p>
        
        <h2>2. Process for Requesting a Refund</h2>
        <p>To request a refund, please follow these steps:
        <ol>
            <li>Contact our support team via <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a> with your account details and purchase information.</li>
            <li>Specify the reason for the refund request to help us understand how we can improve our services.</li>
            <li>Submit your request within the 7-day period from the purchase date.</li>
        </ol>
        </p>
        
        <h2>3. Processing Refunds</h2>
        <p>Once your refund request is received and inspected, we will send you an email to notify you that we have received your request. We will also inform you of the approval or rejection of your refund.
        <br/>If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within a certain number of days, typically within 10 business days.</p>
        
        <h2>4. Late or Missing Refunds</h2>
        <p>If you haven’t received a refund yet:
        <ol>
            <li>First, check your bank account again.</li>
            <li>Then, contact your credit card company or bank as there is often some processing time before a refund is posted.</li>
            <li>If you’ve done all of this and still have not received your refund, please contact us at <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a>.</li>
        </ol>
        </p>

        <h2>5. Exceptions</h2>
        <p>Note that certain types of items cannot be refunded:
        <ul>
            <li>Services that have been fully utilized or accessed beyond the basic operational checks.</li>
            <li>Any service purchased on sale or with a discount.</li>
        </ul>
        </p>

        <h2>Contact Information</h2>
        <p>For any questions or clarifications regarding our refund policy, please contact us at <a href="mailto:glutaclass@gmail.com">glutaclass@gmail.com</a>.</p>
        </div>
        <div className='policy-footer-div'>
            <Footer/>
        </div>
    </>
  )
}

export default RefundPolicy;
