import React, {useEffect, useState, useRef} from 'react';
import './LandingPage.css';
import QrioImg from '../assets/qrio.svg';
import PictionaryImg from '../assets/pictionary.svg';
import DebateImg from '../assets/debate.svg';
import MysteryCasesImg from '../assets/mystery.svg';
import LateralThinkingImg from '../assets/lateral-thinking.svg';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { getEventsApi } from '../api/getEvents.api';
import EventRegistrationModal from '../components/EventRegistrationModal';
import Tick from '../assets/tick-green.gif';




function LandingPage() {
    const [upcomingSessions, setUpcomingSessions] = useState(null);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [registered, setRegistered] = useState(false);

    // const upcomingSessions = [
    //     {
    //         "id": "event_1",
    //         "name": "The disappearence of Dr. Greene and the unusual suspects",
    //         "topicsCovered": [
    //         "Constellations",
    //         "Prime Numbers",
    //         "Periodic Table",
    //         "Great Fire"
    //         ],
    //         "softSkillsFocussed": [
    //         "Communication",
    //         "Critical Thinking"
    //         ],
    //         "eventTime": 1725111000,
    //         "spotsLeft": 4,
    //         "totalSlots": 10
    //     }
    // ];

    const typeOfActivities = [
        {
            "id": "activity_1",
            "name": "Academic Pictionary",
            "description": "Where Academic Concepts Come to Life Through Drawing! Boosts creativity and enhanced memory for academic concepts. Education & Fun.",
            "img": PictionaryImg
        },
        {
            "id": "activity_2",
            "name": "Insightful Debates",
            "description": "the Ultimate Debate Experience: Sharpen Your Mind, Express Your Ideas, and Have Fun! Develop Critical Thinking, Public Speaking, and Teamwork Skills!",
            "img": DebateImg
        },
        {
            "id": "activity_3",
            "name": "Mystery Cases",
            "description": "Crack the Case with Critical Thinking! Dive into a Virtual Mystery Game Where Academic Skills in Science, History, and Logic Are Key to Solving the Puzzle",
            "img": MysteryCasesImg
        },
        {
            "id": "activity_4",
            "name": "Lateral Thinking",
            "description": "Boost Problem-Solving Skills with Fun and Challenging Lateral Thinking Games! Perfect for Students to Explore New Ways of Thinking.",
            "img": LateralThinkingImg
        }
    ];

    const fetchAllEvents = async () => {
      try{
        const events = await getEventsApi();
        console.log(events);
        setUpcomingSessions(events);
      }catch(error){
        console.log(error);
      }
    }


    useEffect(() => {
        fetchAllEvents();
    }, []);

    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setRegistered(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const whatsAppNumber = '+919654073981'; 
    // const preFilledMessage = 'Hello, I would like to reserve a slot in the upcoming session.';

    const handleSlotReservation = (session) => {
        const completeMessage = `Hello, I would like to reserve a slot in the upcoming session '${session.name}' happening at ${convertEpochEventTime(session.eventTime)}.`
        const encodedMessage = encodeURIComponent(completeMessage); 
        const whatsappUrl = `https://wa.me/${whatsAppNumber}?text=${encodedMessage}`;
        window.location.href = whatsappUrl;
    };

    const handleJoining = () => {
        const completeMessage = `Hello, I am interested in joining Qrio Space.`
        const encodedMessage = encodeURIComponent(completeMessage); 
        const whatsappUrl = `https://wa.me/${whatsAppNumber}?text=${encodedMessage}`;
        window.location.href = whatsappUrl;
    };

    const convertEpochEventTime = ( eventTime ) => {
        const date = new Date(eventTime * 1000);

        const options = {
            month: 'short', // Aug
            day: 'numeric', // 31
            hour: 'numeric', // 7 PM
            // minute: 'numeric', // 00 (optional, you can remove if not needed)
            hour12: true // 12-hour format with AM/PM
        };

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    };

    const handleSlotReservationV2 =(session)=>{
        setSelectedEvent(session);
        setShowRegistrationModal(true);
    }

    const handleRegistrationResponse = (registrationSuccess)=>{
        if(registrationSuccess){
            setRegistered(true);
        }
        setShowRegistrationModal(false);

    }

    const RegistrationSuccessModal = () => {
        return (
        registered && (
            <div className="registration-modal">
            <div className="generic-modal" ref={modalRef}>
                <img src={Tick} alt='tick'></img>
                <h2>Successfully Registered!</h2>
                <a href={selectedEvent.resourceUrl} download={`${selectedEvent.name}.pdf`}>
                    <button>Get Resources Now</button>
                </a>
            </div>
            </div>
        )
        );
    };

    return (
        <div className='landing-page'>
            <Helmet>
                <title>Qrio Space | Ignite Your Curiosity</title>
                <link rel="canonical" href="https://qrio.space" />
                <meta name="description" content="Qrio Space offers various interesting online sessions where kids can come together to solve mysteries and cases." />
                <meta name="keywords" content="  Curiosity Driven Learning, Soft Skills , K-12 Education, Peer Learning , Communication , Critical Thinking" />
                <meta property="og:title" content="Qrio Space | Ignite Your Curiosity" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="hhttps://qrio.space" />
                <meta property="og:image" content="https://qrio.space/Qrio-Logo.svg" />
                <meta property="og:description" content="Qrio Space offers various interesting online sessions where kids can come together to solve mysteries and cases." />
                <meta property="og:site_name" content="Qrio Space" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@Shubhamsahu06" />
                <meta name="twitter:title" content="Qrio Space | Ignite Your Curiosity" />
                <meta name="twitter:description" content="Qrio Space offers various interesting online sessions where kids can come together to solve mysteries and cases." />
                <meta name="twitter:image" content="https://qrio.space/Qrio-Logo.svg" />
                <meta name="twitter:url" content="https://qrio.space" />
            </Helmet>
            {showRegistrationModal && <EventRegistrationModal event={selectedEvent} registrationSuccess={handleRegistrationResponse} />}
            {registered && <RegistrationSuccessModal/>}
            <div className='intro-section'>
                <div className='text-part'>
                    <h1>Qrio Space</h1>
                    <h3>Where smartest kids come together to solve the</h3>
                    <h2>Biggest Mysteries</h2>
                </div>
                <div className='img-part'>
                    <img src={QrioImg} alt='Qrio Space Into Image' />
                </div>
            </div>
            <div className='upcoming-sessions'>
                { upcomingSessions && upcomingSessions.map(session=>(
                    <div className='session' key={session.id}>
                        <div className='left'>
                            <h2>{session.name}</h2>
                            <div>
                                {session.topicsCovered.map((topic, index) => (
                                    <p key={index}>
                                        {topic}
                                        {index < session.topicsCovered.length - 1 && ' | '}
                                    </p>
                                ))}
                                {/* <p>{convertEpochEventTime(session.eventTime)}</p> */}
                            </div>
                        </div>
                        <div className='right'>
                            <div>
                                <p>{convertEpochEventTime(session.eventTime)} | </p>
                                <p>{session.spotsLeft} spots left</p>
                            </div>
                            <button onClick={()=>handleSlotReservationV2(session)}>Reserve Your Seat</button>
                        </div>
                    </div>
                ))}
            </div>
            <div className='activities'>
                {typeOfActivities.map(activity=>(
                    <div className='activity' key={activity.id}>
                        <div className='img'>
                            <img src={activity.img} alt={activity.name}></img>
                        </div>
                        <div className='details'>
                            <h2>{activity.name}</h2>
                            <p>{activity.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className='join-now'>
                <div className='left'>
                    <h2>Join Qrio Space to stay updated on upcoming sessions</h2>
                    <p>Fun Zoom Sessions Every Week</p>
                </div>
                <div className='right'>
                    <button onClick={handleJoining}>Join Now</button>
                </div>
            </div>
            {/* <div>
                <iframe src="testing.html" frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <a href="https://s3.ap-south-1.amazonaws.com/qrio.space/Missing+Person+Mystery+Google+Slides.pdf" download="MyAwesomePDF.pdf">
                <button>Download PDF</button>
            </a> */}
            <Footer/>
        </div>
    )
}
export default LandingPage;
