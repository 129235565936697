import httpClient from "../services/http.service";
import { EVENT_REGISTRATION } from "./api-constants";

export const eventRegistration = async ( data) => {
    try {
        const response = await httpClient.post(`${EVENT_REGISTRATION}`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}