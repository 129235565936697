import { Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy";
import TnC from "../pages/TnC";
import { LANDING_ROUTE_PATH, PRIVACY_POLICY, REFUND_POLICY, TNC } from "./route-constants";

const Router = () => {
    return (
        <Routes>

            <Route
                path={LANDING_ROUTE_PATH}
                element={
                    <LandingPage/>
                }
            />
            <Route 
                path={TNC}
                element={
                    <TnC/>
                }
            />
            <Route 
                path={PRIVACY_POLICY}
                element={
                    <PrivacyPolicy/>
                }
            />
            <Route 
                path={REFUND_POLICY}
                element={
                    <RefundPolicy/>
                }
            />
        </Routes>
        
        
    )
}

export default Router;