import React, {useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import './EventRegistrationModal.css';
import ExposureImg from  '../assets/exposure.svg';
import SoftSkillsImg from '../assets/soft-skills.svg';
import CuriosityImg from '../assets/curiosity.svg';
import TimeupImg from '../assets/Qrio-Logo.svg';
import PhoneInput from 'react-phone-input-2';
import { eventRegistration } from '../api/eventRegistration.api';


function EventRegistrationModal({ event, registrationSuccess }) {
    const modalRef = useRef(null);

    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            registrationSuccess(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [subscriptionBenefits, setSubscriptionBenefits] = useState([]);

    const joinWithAnd=(array)=> {
        if (array.length === 0) return '';
        if (array.length === 1) return array[0];
        if (array.length === 2) return array.join(' and ');
        return `${array.slice(0, -1).join(', ')}, and ${array[array.length - 1]}`;
    }

    useEffect(() => {
        if (event) {
            const benefits = [
                {
                    name: "Ignite Curiosity",
                    description: event.curiosity,
                    img: CuriosityImg
                },
                {
                    name: "Get Exposure",
                    description: event.topicsCovered.join(" | "),
                    img: ExposureImg
                },
                {
                    name: "Boost Soft Skills",
                    description: joinWithAnd(event.softSkillsFocussed),
                    img: SoftSkillsImg
                }
            ];
            setSubscriptionBenefits(benefits);
        }
    }, [event]);

    // const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
    // const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleSubmit = async(e) => {
        e.preventDefault();
        try{
            const response = await eventRegistration({
                name: name,
                phoneNo: phone,
                emailId: email,
                eventId: event.id
            })
            if(response){
                registrationSuccess(true);
            }
        }
        catch(error){

        }
        console.log("User Info:", { name, phone, email });
    };

    const convertEpochEventTime = ( eventTime ) => {
        const date = new Date(eventTime * 1000);

        const options = {
            month: 'short', // Aug
            day: 'numeric', // 31
            hour: 'numeric', // 7 PM
            // minute: 'numeric', // 00 (optional, you can remove if not needed)
            hour12: true // 12-hour format with AM/PM
        };

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        return formattedDate;
    };

    return (
        <div className="registration-modal">
            <div className='rm-cont' ref={modalRef}>
                <div className='subcription-benefits mobile-hide'>
                    <div className='sb-top'>
                        <h2>{event.name}</h2>
                        <p>{convertEpochEventTime(event.eventTime)}</p>
                    </div>
                    <p style={{ margin:'0', marginTop:'1rem' }}>OUTLINE OF THE SESSION</p>
                    <div className='subscription-benefit-list'>
                        {subscriptionBenefits.map((benefit, index) => (
                            <div key={benefit.name}>
                                <img src={benefit.img} alt={benefit.name}></img>
                                <div className='sub-benefit-text'>
                                    <h2>{benefit.name}</h2>
                                    <p>{benefit.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='registration-inner-modal'>
                    <img className='timeup-img' src={event.imageUrl} alt={event.name}></img>
                    {/* <h2>{event.name}</h2>
                    <p>{convertEpochEventTime(event.eventTime)}</p> */}
                    <h2>Contact Information</h2>
                    <form onSubmit={handleSubmit} className="contact-form">
                        <div className="form-group">
                            <label>Name</label>
                            <input 
                                type="text" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <PhoneInput
                                country={"in"}
                                value={phone}
                                onChange={setPhone}
                                placeholder="Enter your mobile number"
                                autoFormat={false}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input 
                                type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                required 
                            />
                        </div>
                        <button type="submit" className="submit-button" disabled={phone.length!=12}>Register Now</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default EventRegistrationModal;
